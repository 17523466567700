<template>
  <div class="login-main">
    <!-- 登录主题 -->
    <div class="input-main">
      <div class="login-title">欢迎登录</div>
      <div>
        <div class="label">账号</div>
        <div>
          <el-input
              v-model="loginInfo.account"
              placeholder="请输入账号"
              clearable
          ></el-input>
        </div>
      </div>
      <div>
        <div class="label">密码</div>
        <div>
          <el-input
              v-model="loginInfo.password"
              type="password"
              placeholder="请输入密码"
              clearable
          ></el-input>
        </div>
      </div>

      <div>
        <el-button type="primary" style="width: 100%" @click="login">
          登录
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/api/request";
import Cookies from "js-cookie";
import {Encrypt} from "@/utils/crypto"

export default {
  components: {},
  data() {
    return {
      loginInfo: {
        account: '',
        password: ''
      },
    }
  },
  methods: {
    loginFun() {
      this.loginInfo.password = Encrypt(this.loginInfo.password);
      request({
        url: "/account-info/login",
        method: "post",
        data: JSON.stringify(this.loginInfo)
      })
          .then((res) => {
            console.info(res)
            if (res.data.code === 200) {
              this.msgSuccess('登录成功')
              Cookies.set('userInfo', JSON.stringify(res.data.data))
              Cookies.set('Token', res.data.message)
              this.$router.replace('/license')
            } else {
              this.msgError(res.data.message)
            }
          })
          .catch((error) => {
            console.error(error)
          });
    },
    login() {
      if (!this.loginInfo.account) {
        this.msgError('请输入账号')
        return
      }
      if (!this.loginInfo.password) {
        this.msgError('请输入密码')
        return
      }
      // 调用登录方法
      this.loginFun();
    },
  },
}
</script>

<style scoped lang="less">
.login-main {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url('../assets/loginbg2.jpg');
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .input-main {
    width: 350px;
    height: 350px;
    background-color: white;
    border-radius: 5px;
    padding: 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
  }
}

.login-title {
  color: #409eff;
  font-size: 25px;
  font-weight: 700;
}

.label {
  margin-bottom: 10px;
}
</style>